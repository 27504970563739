import { connect } from "react-redux";

import { getShiftCodes } from "../../action-creators/shiftCodes";
import ShiftCodeList, {
  StateProps,
  DispatchProps
} from "../presentationals/ShiftCodeList";
import { AppState } from "../../reducers/rootReducer";

const mapStateToProps = (state: AppState): StateProps => ({
  shiftCodes: state.shiftCodes.list
});

const mapDispatchToProps: DispatchProps = {
  getShiftCodes
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftCodeList);
