import React, { useState } from "react";
import moment from "moment";

import { ShiftCode } from "../../reducers/shiftCodes";

interface ShiftCodeProps extends ShiftCode {}

function ShiftCodeCard(props: ShiftCodeProps) {
  const { postedAt, shiftCode, tags, twitterAccounts } = props;
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const isNewShiftCode = moment().diff(moment(postedAt), "hours") <= 7;

  const handleClick = () => {
    navigator.clipboard.writeText(shiftCode);
    setShowCopiedMessage(true);
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 3000);
  };

  return (
    <div
      className={`borderlands-card ${isNewShiftCode ? "new-shift-code" : ""}`}
      onClick={handleClick}
    >
      <div className="borderlands-card-container">
        <div className="shift-code">
          {showCopiedMessage ? "Copied to clipboard !" : shiftCode}
        </div>
        <div className="borderlands-card-description">
          Published {moment(postedAt).fromNow()} on Twitter by{" "}
          {twitterAccounts.join(", ")}
        </div>
        {tags.length > 0 && (
          <div className="borderlands-card-tags-container">
            {tags.map(tag => (
              <div
                key={tag}
                className={`borderlands-card-tag ${tag
                  .toLowerCase()
                  .replace(/ /g, "")}`}
              >
                {tag}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ShiftCodeCard;
