import { applyMiddleware, createStore, Middleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import rootReducer from "../reducers/rootReducer";

const hideReduxLogger = process.env.NODE_ENV === "production";
const middlewareList: Middleware[] = [thunk];

if (!hideReduxLogger) {
  middlewareList.push(
    createLogger({
      collapsed: true
    })
  );
}

const middlewares = applyMiddleware(...middlewareList);

const configureStore = (preloadedState = {}) =>
  createStore(rootReducer, { ...preloadedState }, middlewares);

export default configureStore;
