import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import { subscribeUser } from './services/notifications'

import "./index.css";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-70908222-4");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

subscribeUser()
  .catch(e => {
    console.error(e)
  }) 