
import Api from './Api'

const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const sendSubscription = (subscription: PushSubscription) => Api.post(`${process.env.REACT_APP_API_URL}/notifications/subscribe`, subscription)

export const getPushManager = async () => {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready
    return registration.pushManager
  }
}

export const getExistingSubscription = async () => {
  const pushManager = await getPushManager()
  if (!pushManager) {
    return null
  }
  const existingSubscription = await pushManager.getSubscription()
  return existingSubscription
}

export const subscribeUser = async () => {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready
    if (!registration.pushManager) {
      return null
    }
    const existingSubscription = await registration.pushManager.getSubscription()
    if (!existingSubscription) {
      const newSubscription = await registration.pushManager.subscribe({
        // @ts-ignore
        applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID),
        userVisibleOnly: true
      })
      sendSubscription(newSubscription)
    }
  }
}