import { get, uniqBy } from "lodash";

import {
  GET_SHIFT_CODES_SUCCESS,
  ShiftCodesActionTypes
} from "../action-creators/shiftCodes";

export interface ShiftCode {
  postedAt: string;
  shiftCode: string;
  tags: string[];
  tweetIds: number[];
  twitterAccounts: string[];
  twitterUrls: string[];
}

export interface ShiftCodeState {
  list: ShiftCode[];
}

const initialState: ShiftCodeState = {
  list: []
};

const shiftCodes = (
  state = initialState,
  action: ShiftCodesActionTypes
): ShiftCodeState => {
  switch (action.type) {
    case GET_SHIFT_CODES_SUCCESS:
      return {
        ...state,
        list: uniqBy([...state.list, ...get(action, "payload")], "shiftCode")
      };
    default:
      return state;
  }
};

export default shiftCodes;
