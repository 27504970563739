import React from "react";
import { Container } from "semantic-ui-react";

import ShiftCodeList from "./components/containers/ShiftCodeList";

import "semantic-ui-css/semantic.min.css";
import "./App.css";

function App() {
  return (
    <Container>
      <header className="app-header">
        <h3 className="header-title">
          Borderlands Shift codes
        </h3>
      </header>
      <main>
        <ShiftCodeList />
      </main>
      <footer className='footer'>
        Build with <span role="img" aria-label="heart icon">❤️</span> by <a className="bmac-link" href="https://www.buymeacoffee.com/HgSL0pxnu" target="_blank" rel="noopener noreferrer">Yomansk8</a>
      </footer>
    </Container>
  );
}

export default App;
