import "whatwg-fetch";

const headers = { 'Content-Type': "application/json" }

export default {
  get: (url: string) =>
    fetch(url, { method: "GET", headers }),
  post: (url: string, body: any) =>
    fetch(url, { method: "POST", body: JSON.stringify(body), headers })

};
