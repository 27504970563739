import React from "react";

import { get } from "lodash";

export type ShiftCodeListFilterType = "All" | "Borderlands 3" | "Borderlands 2";

const filterOptions: {
  text: string;
  value: ShiftCodeListFilterType;
}[] = [
  { text: "All", value: "All" },
  { text: "BDL 3", value: "Borderlands 3" },
  { text: "BDL 2", value: "Borderlands 2" }
];

interface ShiftCodeListFilterProps {
  onFilterChange: (filter: ShiftCodeListFilterType) => void;
}

function ShiftCodeListFilterSelect(props: ShiftCodeListFilterProps) {
  const { onFilterChange } = props;

  const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const filter: ShiftCodeListFilterType = get(event, "target.value");
    onFilterChange(filter);
  };

  return (
    <div className="list-title-filter">
      Filter:{" "}
      <select onChange={handleChange}>
        {filterOptions.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ShiftCodeListFilterSelect;
