import React, { useEffect, useState } from "react";

import ShiftCodeCard from "./ShiftCodeCard";
import ShiftCodeListFilterSelect, {
  ShiftCodeListFilterType
} from "./ShiftCodeListFilterSelect";
import { ShiftCode } from "../../reducers/shiftCodes";

const sortByDate = (a: ShiftCode, b: ShiftCode): number => {
  const date1 = new Date(a.postedAt);
  const date2 = new Date(b.postedAt);
  if (date1 > date2) return -1;
  if (date1 < date2) return 1;
  return 0;
};

export interface StateProps {
  shiftCodes: ShiftCode[];
}

export interface DispatchProps {
  getShiftCodes: () => void;
}

export interface OwnProps {}

type ShiftCodeListProps = StateProps & DispatchProps & OwnProps;

function ShiftCodeList(props: ShiftCodeListProps) {
  const { getShiftCodes, shiftCodes } = props;
  const [filter, setFilter] = useState<ShiftCodeListFilterType>("All");

  const filteredList =
    filter === "All"
      ? shiftCodes
      : shiftCodes.filter(shiftCode => shiftCode.tags.includes(filter));

  useEffect(() => {
    getShiftCodes();
  }, [getShiftCodes]);

  return (
    <>
      <h2 className="list-title">
        <div>Shift codes ({filteredList.length})</div>
        <ShiftCodeListFilterSelect onFilterChange={setFilter} />
      </h2>
      <div>
        {filteredList.sort(sortByDate).map((shiftCode: ShiftCode) => (
          <ShiftCodeCard key={shiftCode.shiftCode} {...shiftCode} />
        ))}
      </div>
    </>
  );
}

export default ShiftCodeList;
