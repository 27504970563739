import { Dispatch } from "redux";

import { ShiftCode } from "../reducers/shiftCodes";
import Api from "../services/Api";

/* Action types */
export const GET_SHIFT_CODES_REQUEST = "GET_SHIFT_CODES_REQUEST";
export const GET_SHIFT_CODES_SUCCESS = "GET_SHIFT_CODES_SUCCESS";
export const GET_SHIFT_CODES_FAILED = "GET_SHIFT_CODES_FAILED";

const API_URL = process.env.NODE_ENV !== 'production' ? 'http://localhost:3005' : process.env.REACT_APP_API_URL

interface GetShiftCodeRequestAction {
  type: typeof GET_SHIFT_CODES_REQUEST;
}
interface GetShiftCodeSuccessAction {
  type: typeof GET_SHIFT_CODES_SUCCESS;
  payload: ShiftCode[];
}
interface GetShiftCodeFailedAction {
  type: typeof GET_SHIFT_CODES_FAILED;
  error: Error;
}

export type ShiftCodesActionTypes =
  | GetShiftCodeRequestAction
  | GetShiftCodeSuccessAction
  | GetShiftCodeFailedAction;

/* Action creators */
export const getShiftCodesRequest = (): ShiftCodesActionTypes => ({
  type: GET_SHIFT_CODES_REQUEST
});

export const getShiftCodesSuccess = (
  shiftCodes: ShiftCode[]
): ShiftCodesActionTypes => ({
  type: GET_SHIFT_CODES_SUCCESS,
  payload: shiftCodes
});

export const getShiftCodesFailed = (error: Error): ShiftCodesActionTypes => ({
  type: GET_SHIFT_CODES_FAILED,
  error
});

/* Thunk action creators */
export const getShiftCodes = () => (
  dispatch: Dispatch<ShiftCodesActionTypes>
) => {
  dispatch(getShiftCodesRequest());
  return Api.get(`${API_URL}/json`).then(async response => {
    const body = await response.json();
    dispatch(getShiftCodesSuccess(body));
    return { payload: body };
  });
};
